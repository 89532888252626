import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import ShowOnButton from "./ShowOnButton"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import { graphql, useStaticQuery } from "gatsby"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const SplitLayout = ({ direction = "start", children, fluid, Logo }) => {
  const { file } = useStaticQuery(FALLBACK)
  const fallback = file.childImageSharp.fluid
  return (
    <Container direction={direction}>
      <Main>{children}</Main>
      <Secondary fluid={fluid || fallback}>
        <Overlay>
          <TopLeft>
            {Logo && (
              <Link to="/">
                <Logo />
              </Link>
            )}
          </TopLeft>
          <BottomLeft>
            <ShowOnButton desktop />
          </BottomLeft>
        </Overlay>
      </Secondary>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Secondary = styled(BackgroundImage)`
  grid-area: secondary;
  position: relative;
`

const Overlay = styled.div`
  background: linear-gradient(45deg, #000000bd, transparent);
  height: 100%;
`

const TopLeft = styled.div`
  width: 350px;
  position: absolute;
  top: 20px;
  left: 20px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

const BottomLeft = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "${({ direction }) =>
    isStart(direction) ? "main secondary" : "secondary main"}";

  min-height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "main";
  }
`

const Main = styled.div`
  grid-area: main;
  background-color: #162232;
  color: white;
  padding: 20px;
  > div > div:first-of-type {
    color: #b6c9e3;
    font-size: 14px;
    a {
      color: #b6c9e3;
      font-size: 14px;
    }
  }

  input {
    border: 1px solid #3e4b5c;
  }

  input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  label,
  p,
  div {
    color: #f0f1f3;
  }
`
// const Secondary = styled(BackgroundImage)`
// 	grid-area: secondary;
// `

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
// style helpers
const isStart = (direction) => direction === "start"

const FALLBACK = graphql`
  {
    file(relativePath: { eq: "excited-couple.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default SplitLayout
